import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

import {useNavigate} from "react-router-dom";

const showcheckarr=[];
const packagenamearr=[];
const perpackageamount=[];
let showcount=0;
let sumamount=0.00;
let countpackage=0;

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function ManageStudentContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    const formgroup2={
        margin: '26px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconpaid={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '50px'
    };

    const picstylelarge={
        width: '300px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [search,setSearch]=useState("");

    let [batchlistarr, setBatchlistarr]=useState([]);
    let [batchslno, setBatchslno]=useState(0);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    let [batchnm,setBatchnm]=useState("");

    let [changestatus, setChangestatus]=useState("");
    let [newpassword, setNewpassword]=useState("");
    let [ispassworderr, setIspassworderr]=useState(false);
    
    let [count,setCount]=useState("");

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studlistarr, setStudlistarr]=useState([]);
    let [loader,setLoader]=useState(false);

    let [pic,setPic]=useState("");
    
    //let [limit,setLimit]=useState(3);

    let limit = 50;

    async function getStudlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("search",search);
        fd.append("limit",limit);
        //alert(pageno);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("search",search);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);
        setLoader(false);
    };

    let [studname,setStudname]=useState("");
    let [studmobile,setStudmobile]=useState("");
    let [studenrollid,setStudenrollid]=useState("");
    
    let [packageplan,setPackageplan]=useState([]);
    async function getPackageplan() {
        // setLoader(true);
        var fd=new FormData();
        fd.append("enrollid",localStorage.getItem("enrollid"));
        var resp=await axios.post("https://safalata.net/safalatalara/api/getpackageplan",fd);
        var data=resp.data;
        setPackageplan(data.obj);
        setLoader(false);
      
        // console.log(localStorage.getItem("enrollid"));
  
      }
    
    let [packagename,setPackagename]=useState([]);
    let [showcheck,setShowcheck]=useState([]);

    let [discounttype,setDiscounttype]=useState("");
    let [discount,setDiscount]=useState("");
    let [discountamount,setDiscountamount]=useState(0.00);
    let [totalpackage,setTotalpackage]=useState("");

    let [grandtotal,setGrandtotal]=useState(0.00);
    let [sumtotal,setSumtotal]=useState(0.00);
    const [isMobile, setIsMobile] = useState(false);

    let [packagelist,setPackagelist]=useState([]);
    let [packagecount,setPackagecount]=useState(0);
    let [packageamount,setPackageamount]=useState(0);
    let [couponcode,setCouponcode]=useState("");

    let [couponamount,setCouponamount]=useState(0);

    let [arraycount,setArraycount]=useState(0);
    let [apply,setApply]=useState("Apply");

    
    function updatecart() {
        setPackagelist(showcheckarr);
        // setArraycount(parseInt(showcheck.length)+1);
        setPackagecount(showcount);
        setPackageamount(sumamount);
        setTimeout(()=>{
            console.log(packagelist);
            console.log(showcount);
        },500)     
        
        
    }

    async function pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal) {
        if (countpackage!=0) {
            var fd=new FormData();
            fd.append("oid",oid);
            fd.append("grandtotal",grandtotal);
            fd.append("packageids",packageids);
            fd.append("enrollid",studenrollid);
            fd.append("countpackage",countpackage);
            fd.append("discounttype",discounttype);
            fd.append("discount",discount);
            fd.append("couponcode",couponcode);
            fd.append("discountamount",discountamount);
            fd.append("sumtotal",sumtotal);
            var resp=await axios.post("https://safalata.net/safalatalara/api/gopackagepayment_byadmin",fd);
            var data=resp.data;
            console.log(data.statcode);

            showcheckarr.splice(0, showcheckarr.length);

            if (data.statcode==1) {
                alert("Payment Successfully Updated");
                setIsOpen(false);
            }

        } else {
            alert("Please select package first");
        }
        
    }

    useEffect(()=>{
        getStudlist();
        // setPageno(1);
        getBatchlist();

        getPackageplan();

        updatecart();

    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MANAGE STUDENT</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{
                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("search","");
                            fd.append("limit",limit);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
                            var data=resp.data;
                            setStudlistarr(data.obj);
                            setCount(data.datacount);

                            const total=data.datacount;

                            setPageCount(Math.ceil(total/limit));
                            setLoader(false);
                        }
                    }} placeholder='Search Student Name OR Enroll ID OR Mobile No'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH</button>
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    STUDENT LIST - TOTAL NO OF STUDENTS ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod} className='text-center'>Identity</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>DOB</th>
                                    <th style={txtblod}>Mobile</th>
                                    <th style={txtblod}>City</th>
                                    <th style={txtblod} className='text-center'>Enroll No</th>
                                    <th style={txtblod}>Batch</th>
                                    <th style={txtblod} className='text-center'>Enroll Date</th>
                                    {/* <th style={txtblod}>MA</th>
                                    <th style={txtblod}>PGDT</th> */}
                                    <th style={txtblod}>Status</th>
                                    <th style={txtblod} className='text-center'>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studlistarr.map((e)=>
                                
                                
                                
                                <tr key={e.id}>
                                    <td className='text-center'><Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(4);
                                        setPic(e.pic);                                        
                                        }}><img src={"https://safalata.net/safalatalara/uploadpic/"+e.pic} style={picstyle}/></Link></td>
                                    <td style={txtblod}>{e.name}</td>
                                    <td style={txtblod}>{dateFormat(e.dob, "dd-mm-yyyy")}</td>
                                    <td>{e.phone}</td>
                                    <td style={txtblod}>{e.city}</td>
                                    <td className='text-center' style={txtblod}>{e.enroll_id>0?e.enroll_id:<button className='btn-default' onClick={async ()=>{
                                        if(window.confirm('Sure, Confirm Enrollment?')) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/createenrollid",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                    }}><i className='fa fa-screwdriver' style={iconscrew}></i></button>}</td>
                                   
                                    <td style={txtblod}>{e.batch_name} <Link to="" className='btn-default' onClick={async ()=>{
                                        openModal();
                                        setModalid(1);
                                        setId(e.id);
                                        setName(e.name);
                                        setBatchnm(e.batch_name);
                                    }}><i className='fas fa-exchange-alt' style={iconscrew}></i></Link></td>
                                    <td className='text-center'>{e.enroll_date?dateFormat(e.enroll_date, "dd-mm-yyyy"):''}</td>
                                    {/* <td>{e.ma==1?'Completed':e.ma==2?'Pursuring':e.ma==3?'Others':''}<br/>{e.mapassingyr}</td>
                                    <td>{e.pgdt==1?'Completed':e.pgdt==2?'Pursuring':e.pgdt==3?'Others':''}<br/>{e.pgdtpassingyr}</td> */}
                                    {e.status==0?<td style={txtgrey}>Pending</td>:''}
                                    {e.status==1?<td style={txtgreen}>Active</td>:''}
                                    {e.status==2?<td style={txtred}>Inactive</td>:''}
                                    
                                    <td className='text-center'>
                                        <Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(2);
                                        setId(e.id);
                                        setName(e.name);
                                        setStatus(e.status);
                                        }}><i className="fas fa-edit" style={iconedit}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={()=>{
                                        openModal();
                                        setModalid(3);
                                        setId(e.id);
                                        setName(e.name);
                                        }}><i className="fas fa-key" style={iconkey}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={async ()=>{
                                        if(window.confirm("Sure, Allow for Success Story??")) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            fd.append("successstory",1);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                        }}><i className="fas fa-trophy" style={icontrophy}></i></Link>&nbsp;&nbsp;
                                        <Link to="" className='btn-default' onClick={async ()=>{
                                        if(window.confirm("Sure, Delete Student from SAFALATA?")) {
                                            var fd=new FormData();
                                            fd.append("uid",e.id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/dashboarddatadelete",fd);
                                            var data=resp.data;
                                            getStudlist();
                                        }
                                        }}><i className="fas fa-trash" style={icontrash}></i></Link><br/>
                                        {e.enroll_id.length>0?
                                        <Link to="" className='btn-default' onClick={async ()=>{
                                        
                                        showcount=0;
                                        sumamount=0.00;
                                        
                                        setGrandtotal(0.00);

                                        setDiscounttype("");
                                        setDiscount("");
                                        setDiscountamount(0.00);
                                        setTotalpackage("");
                                        setSumtotal(0.00);
                                        setPackagelist([]);
                                        setPackagecount(0);
                                        setPackageamount(0);
                                        setCouponcode("");

                                        openModal();
                                        setModalid(5);
                                        setId(e.id);
                                        setStudname(e.name);
                                        setStudmobile(e.phone);
                                        setStudenrollid(e.enroll_id);

                                        // packagenamearr.push("");

                                        setPackagename([]);

                                        // showcheckarr.push(undefined);

                                        setShowcheck([]);

                                        // alert(packagenamearr);
                                        
                                        }}>Pay</Link>
                                        :''}
                                    </td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

            

        </div>


        {/* For Modal */}
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT BATCH ALTERATION:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT BATCH - {batchnm}
            </div>
            
            <div className="form-group" style={formgroup}>
                <label>Change Batch - </label>
                    <select value={batchslno} className="form-control" onChange={(ev)=>{
                        setBatchslno(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }} >
                        <option value="">-------------------------Select Batch-------------------------</option>
                        {batchlistarr.map((e)=>
                            <option value={e.batch_slno} key={e.batch_slno}>{e.batch_name}</option>
                        )}
                    </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("batchslno",batchslno);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);
                //batchwisefetchlist();
                getStudlist();                
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT STATUS:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS - {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Status----</option>
                    <option value="0">Pending</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);       
                getStudlist()         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}
        {modalid==3?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PASSWORD CHANGE:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpassword(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                    setIspassworderr(false);
                }} placeholder='Atleast 6 characters password'/>
                {ispassworderr?<div className='text text-danger'>Atleast 6 length password required!</div>:''}
                    
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var flag=1;

                if (newpassword.length<6) {
                    setIspassworderr(true);
                    flag=0;
                }

                if (flag==1) {
                    var fd=new FormData();
                    fd.append("id",id);
                    fd.append("newpassword",newpassword);
                    var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                    var data=resp.data;
                    setIsOpen(false);       
                    getStudlist();
                }
                         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}

        {modalid==4?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PIC:::
            </div>
            <div className='form-group' style={formgroup}>
            <img src={"https://safalata.net/safalatalara/uploadpic/"+pic} style={picstylelarge}/>
            </div>
            
            
            <div className="form-group" style={formgroup}>
            <button onClick={()=>{
                    setIsOpen(false);
            }} className="btn btn-primary">Close</button>
            </div>
            
        </Modal>
        :''}

        {modalid==5?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup2}>
                :::STUDENT PAYMENT ACTIVATION::
            </div>

            <div className='form-group' style={formgroup2}>
                NAME - {studname}
            </div>
            <div className='form-group' style={formgroup2}>
                MOBILE NO - {studmobile}
            </div>

            <div className='form-group'>

            <Box sx={{ flexGrow: 1 }}>

            {packageplan.map((e)=>


            // {e.packagevaliditycount==0? "ddd" : ''}

            <Grid container spacing={0} key={e.package_id} mb={1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 2, pl:0.5,pr:0, pt: 0.5, pb: 0 }}>
            
                <Grid item xs={12} spacing={1} sx={{background: '#e3f2fd'}}>
                    <Item sx={{fontSize:  '1.2rem', textAlign: 'left', fontWeight: 900, border: 0, pt: 0}}>
                        <FormControlLabel control={<Checkbox {...label} color="info" defaultChecked='' />} onChange={(c)=>{
                        // disabled={e.packagevaliditycount>0? 'true' : ''}
                        // defaultChecked={e.packagevaliditycount>0? 'true' : ''}
                        setId("");
                        setDiscounttype("");
                        setDiscount("");
                        setCouponcode("");
                        setDiscountamount(0.00);

                        if (c.target.checked==true) {                                            

                            showcheckarr.push(e.package_id);

                            showcount=parseInt(showcount)+parseInt(1);
                            sumamount=parseFloat(sumamount)+parseFloat(e.package_amount);
                            packagenamearr.push(e.package_name);
                            perpackageamount.push(e.package_amount);
                            setGrandtotal(sumamount);
                            setSumtotal(sumamount);
                            setPackagename(packagenamearr);

                            setShowcheck(showcheckarr);

                        } else {
                            const idx = showcheckarr.indexOf(e.package_id);
                            showcheckarr.splice(idx, idx !== -1 ? 1 : 0);

                            showcount=parseInt(showcount)-1;
                            sumamount=parseFloat(sumamount)-parseFloat(e.package_amount);
                            const idx2 = packagenamearr.indexOf(e.package_name);
                            packagenamearr.splice(idx2, idx2 !== -1 ? 1 : 0);

                            const idx3 = perpackageamount.indexOf(e.package_amount);
                            perpackageamount.splice(idx3, idx3 !== -1 ? 1 : 0);
                            setGrandtotal(sumamount);
                            setSumtotal(sumamount.toFixed(2));
                            setPackagename(packagenamearr);

                            setShowcheck(showcheckarr);

                        }

                        updatecart();
                        
                    }}/> {isMobile && e.package_name.length>=15? e.package_name.substr(0,18) : e.package_name} ({e.package_amount})</Item>
                </Grid>

                

                
            </Grid>
            )}  


            <Grid container spacing={0} mb={4} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                <Grid item xs={7}>
                    <Item sx={{fontSize:  '1.3rem', textAlign: 'right', fontWeight: 700}}>
                    TOTAL AMOUNT
                    </Item>
                </Grid>
                <Grid item xs={5}>
                    <Item sx={{fontSize:  '1.3rem', textAlign: 'right', fontWeight: 700}}>
                    Rs. {grandtotal.toFixed(2)}
                    </Item>
                </Grid>
            </Grid> 


            </Box>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>


                <Typography align="center" variant="h6" component="h6" sx={{fontSize: '1.5rem', pt: 1.1, pb: 1, color: '#fff', backgroundColor: '#0000ff', cursor: 'pointer'}}  onClick={()=>{
                    var enroll_id=localStorage.getItem("enrollid");
                    if (showcount==0) {
                        //PAYMENT GATEWAY CODE
                        var packageids=showcheck;
                        var oid = "";
                        countpackage=0;                                    
                        pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal);
                    } else {
                        //PAYMENT GATEWAY CODE
                        var packageids=showcheck;
                        var oid = Math.random().toString(16).slice(2);
                        countpackage=showcount;                                    
                        pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal);
                    }
                    }}>
                    PAY NOW {grandtotal!=0 || grandtotal!=0.00? 'Rs.'+grandtotal : ''}
                </Typography>
            </Paper>

            </div>

            {/* <div className="form-group" style={formgroup}>
            <button onClick={()=>{
                    setIsOpen(false);
            }} className="btn btn-primary">Close</button>
            </div> */}
            
        </Modal>
        :''}

        {/* FOr Modal */}
        </>
    );

}

export default ManageStudentContent;