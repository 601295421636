import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

let slno=0;

function CouponSettingContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

   

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const selectright={
        paddingRight: '10px'
    };

    let [loader,setLoader]=useState(true);

    
    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [newsubjectname,setNewsubjectname]=useState("");

    // For Modal
    let [subjectname,setSubjectname]=useState("");
    //let [subjectid,setSubjectid]=useState("");
    let [subjectslno,setSubjectslno]=useState("");

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal

    let [fordays, setFordays]=useState("");
    let [startdate, setStartdate]=useState("");
    let [exam, setExam]=useState("");

    let [nextscheduledate,setNextscheduledate]=useState("");

    // let [isresetbtn,setIsresetbtn]=useState(false);

    let [password, setPassword]=useState("");

    let [couponarr,setCouponarr]=useState([]);

    let [id,setId]=useState("");
    let [couponcode,setCouponcode]=useState("");
    let [totalpackage,setTotalpackage]=useState("");
    let [discounttype,setDiscounttype]=useState("");
    let [discount,setDiscount]=useState("");
    let [description,setDescription]=useState("");

    let [couponcodeerr,setCouponcodeerr]=useState("");
    let [totalpackageerr,setTotalpackageerr]=useState("");
    let [discounttypeerr,setDiscounttypeerr]=useState("");
    let [discounterr,setDiscounterr]=useState("");
    let [descriptionerr,setDescriptionerr]=useState("");
    

    async function getcouponsetting() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/getcouponsetting");
        var data=resp.data;
        setCouponarr(data.obj);

        setLoader(false);
    }

    useEffect(()=>{
        getcouponsetting();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">New Coupon Entry</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 form-group">
                    <div style={selectright}>
                        <input className="form-control" placeholder='Coupon Code [e.g. SAF20RAJBHASHA]' onChange={(ev)=>{
                        setCouponcode(ev.target.value);
                        }} value={couponcode}/>
                        <div className='text text-danger'>{couponcodeerr}</div>
                    </div>
                </div>

                <div className="mt-4 form-group">
                    <select className="form-control form-select" onChange={(ev)=>{
                        setTotalpackage(ev.target.value);                      
                    }} value={totalpackage}>
                    <option value="">--NO OF PACKAGE--</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    </select>
                    <div className='text text-danger'>{totalpackageerr}</div>
                </div>

                <div className="mt-4 form-group">
                    <select className="form-control form-select" onChange={(ev)=>{
                        setDiscounttype(ev.target.value);                      
                    }} value={discounttype}>
                    <option value="">--Discount Type--</option>
                    <option value="PERCENT">PERCENT</option>
                    <option value="FIXED">FIXED</option>
                    </select>
                    <div className='text text-danger'>{discounttypeerr}</div>
                </div>

                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='Discount [e.g. 15]' onChange={(ev)=>{
                        setDiscount(ev.target.value);                        
                    }} value={discount}/>
                    <div className='text text-danger'>{discounterr}</div>
                </div>
                
                <div className="mt-4 form-group">
                    <input type="text" className="form-control" placeholder='Description' onChange={(ev)=>{
                        setDescription(ev.target.value);                        
                    }} value={description}/>
                    <div className='text text-danger'>{descriptionerr}</div>
                </div>
                

                <div className="mt-4 form-group">
                    <span className="form-group">
                        <button className="btn btn-info" type="button" onClick={async ()=>{
                            var flag=1;

                            if (couponcode.length==0) {
                                flag=0;
                                setCouponcodeerr('please enter')
                            } else {
                                setCouponcodeerr('');
                            }

                            if (totalpackage.length==0) {
                                flag=0;
                                setTotalpackageerr('please select')
                            } else {
                                setTotalpackageerr('');
                            }

                            if (discounttype.length==0) {
                                flag=0;
                                setDiscounttypeerr('please select')
                            } else {
                                setDiscounttypeerr('');
                            }

                            if (discount.length==0) {
                                flag=0;
                                setDiscounterr('please enter')
                            } else {
                                setDiscounterr('');
                            }

                            if (description.length==0) {
                                flag=0;
                                setDescriptionerr('please enter')
                            } else {
                                setDescriptionerr('');
                            }

                            if (flag==1) {
                                var fd=new FormData();
                                fd.append("couponcode",couponcode);
                                fd.append("totalpackage",totalpackage);
                                fd.append("discounttype",discounttype);
                                fd.append("discount",discount);
                                fd.append("description",description);
                                var resp=await axios.post("https://safalata.net/safalatalara/api/addcoupon",fd);
                                var data=resp.data;
                                setCouponcode("");
                                setTotalpackage("");
                                setDiscounttype("");
                                setDiscount("");
                                setDescription("");

                                getcouponsetting();
                                
                            }
                            
                            
                        }}>ADD NEW COUPON</button>
                    </span>
                </div>

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    COUPON DETAILS
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    {/* <th className='text-center' style={txtblod}>#</th> */}
                                    {/* <th className='text-center' style={txtblod}>SL NO</th> */}
                                    <th className='text-center' style={txtblod}>NO OF PACKAGE</th>
                                    <th className='text-center' style={txtblod}>COUPON CODE</th>
                                    <th className='text-center' style={txtblod}>DISCOUNT TYPE</th>
                                    <th className='text-center' style={txtblod}>DISCOUNT</th>
                                    <th className='text-center' style={txtblod}>DETAILS</th>
                                    <th className='text-center' style={txtblod}>STATUS</th>
                                    <th className='text-center' style={txtblod}>ACTION</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {couponarr.map((e)=>
                                <tr key={e.id}>
                                    {/* <td className='text-center' style={txtblod}>{slno}</td> */}
                                    <td className='text-center' style={txtblod}>{e.total_package}</td>
                                    <td className='text-center' style={txtblod}>{e.coupon_code}</td>
                                    <td className='text-center' style={txtblod}>{e.discount_type}</td>
                                    <td className='text-center' style={txtblod}>{e.discount}</td>
                                    <td className='text-center' style={txtblod}>{e.description}</td>
                                    {e.status==1?
                                    <td className='text-center' style={txtgreen}>ACTIVE</td>
                                    :<td className='text-center' style={txtred}>CLOSED</td>}
                                    <td className='text-center' style={txtblod}>
                                    <button onClick={async ()=>{
                                        openModal();
                                        setId(e.id);
                                        setCouponcode(e.coupon_code);
                                        setTotalpackage(e.total_package);
                                        setDiscounttype(e.discount_type);
                                        setDiscount(e.discount);
                                        setDescription(e.description);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive coupon?")) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/couponactiveinactive",fd);
                                            var data=resp.data;
                                            getcouponsetting();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active coupon?")) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/couponactiveinactive",fd);
                                            var data=resp.data;
                                            getcouponsetting();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        if(window.confirm("Do you want to delete this coupon?")) {
                                            var fd=new FormData();
                                            fd.append("id",e.id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/coupondelete",fd);
                                            var data=resp.data;
                                            getcouponsetting();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button>    
                                    </td>
                                    
                                </tr>
                                
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>
        </div>

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::COUPON DETAILS:::
            </div>

            <div className="form-group" style={formgroup}>
                <label>Coupon Code::</label>
                <input type="text" className="form-control" value={couponcode} disabled/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Tatal Package::</label>
                <input type="text" className="form-control" value={totalpackage} disabled/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Discount Type::</label>
                <input type="text" className="form-control" value={discounttype} disabled/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Discount::</label>
                <input type="text" className="form-control" value={discount} onChange={(c)=>{
                    setDiscount(c.target.value);
                }}/>
            </div>

            <div className="form-group" style={formgroup}>
                <label>Description::</label>
                <textarea className="form-control" value={description} onChange={(c)=>{
                    setDescription(c.target.value);
                }}/>
            </div>

            
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                // alert(password)
                // setIsresetbtn(true);
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("id",id);
                fd.append("discount",discount);
                fd.append("description",description);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatecoupon",fd);
                var data=resp.data;
                
                setIsnewbtn(true);
                setIsOpen(false);                        
                setId("");
                setCouponcode("");
                setDiscounttype("");
                setDiscount("");
                setDescription("");
                alert(data.msg);
                getcouponsetting();
                                
            }} className="btn btn-primary" >Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default CouponSettingContent;