
import './App.css';

import Login from './layout/Login';

import Home from './layout/Home';
import NewBatch from './layout/NewBatch';
import ManageBatch from './layout/ManageBatch';
import BatchWiseCandidate from './layout/BatchWiseCandidate';
import ManageStudent from './layout/ManageStudent';
import NewCourse from './layout/NewCourse';
import ManageCourse from './layout/ManageCourse';
import NewSubject from './layout/NewSubject';
import ManageSubject from './layout/ManageSubject';

import StudentPayment from './layout/StudentPayment';

import ManageQA from './layout/ManageQA';
import ImportQA from './layout/ImportQA';
import ExportQA from './layout/ExportQA';

import NewLiveExam from './layout/NewLiveExam';
import AddLiveExamSet from './layout/AddLiveExamSet';
import ManageLiveExamSet from './layout/ManageLiveExamSet';
import LiveExamBatchGroup from './layout/LiveExamBatchGroup';
import LiveExamScore from './layout/LiveExamScore';
import LiveScoreView from './layout/LiveScoreView';
import MonthWiseLiveScore from './layout/MonthWiseLiveScore';

import NewPracticeExam from './layout/NewPracticeExam';
import AddPracticeExamSet from './layout/AddPracticeExamSet';
import AddPracticesetAsLive from './layout/AddPracticesetAsLive';
import ManagePracticeExamSet from './layout/ManagePracticeExamSet';
import PracticeExamBatchGroup from './layout/PracticeExamBatchGroup';
import PracticeExamScore from './layout/PracticeExamScore';
import PracticeScoreView from './layout/PracticeScoreView';
import MonthWisePracticeScore from './layout/MonthWisePracticeScore';

import SearchStudentPayment from './layout/SearchStudentPayment';

import Noticeboard from './layout/Noticeboard';

import ChangePassword from './layout/ChangePassword';

import LiveScoreViewPrint from './layout/LiveScoreViewPrint';

import CronSettingsReset from './layout/CronSettingsReset';

import {BrowserRouter, Routes, Route} from 'react-router-dom';
import NewPackage from './layout/NewPackage';
import MoveExamIntoPackage from './layout/MoveExamIntoPackage';
import ModifyPracticeSetName from './layout/ModifyPracticeSetName';
import CouponSettings from './layout/CouponSettings';
import MockStudent from './layout/MockStudent';

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>

      <Route exact path="/login" element={<Login/>}/>

      <Route exact path="/" element={<Home/>}/>
      <Route exact path="/new-batch" element={<NewBatch/>}/>
      <Route exact path="/manage-batch" element={<ManageBatch/>}/>
      <Route exact path="/batch-wise-candidate" element={<BatchWiseCandidate/>}/>
      <Route exact path="/manage-student" element={<ManageStudent/>}/>
      <Route exact path="/new-course" element={<NewCourse/>}/>
      <Route exact path="/manage-course" element={<ManageCourse/>}/>
      <Route exact path="/new-subject" element={<NewSubject/>}/>
      <Route exact path="/manage-subject" element={<ManageSubject/>}/>

      <Route exact path="/student-payment" element={<StudentPayment/>}/>


      <Route exact path="/import-qa" element={<ImportQA/>}/>
      <Route exact path="/export-qa" element={<ExportQA/>}/>
      <Route exact path="/manage-qa" element={<ManageQA/>}/>

      <Route exact path="/new-liveexam" element={<NewLiveExam/>}/>
      <Route exact path="/add-liveexam-set" element={<AddLiveExamSet/>}/>
      <Route exact path="/manage-liveexam-set" element={<ManageLiveExamSet/>}/>
      <Route exact path="/liveexam-batch-group" element={<LiveExamBatchGroup/>}/>
      <Route exact path="/liveexam-score" element={<LiveExamScore/>}/>
      <Route exact path="/livescoreview/:suid/:livexmid" element={<LiveScoreView/>}/>
      <Route exact path="/month-wise-livescore" element={<MonthWiseLiveScore/>}/>

      <Route exact path="/new-practiceexam" element={<NewPracticeExam/>}/>
      <Route exact path="/add-practiceexam-set" element={<AddPracticeExamSet/>}/>
      <Route exact path="/add-practiceset-as-live" element={<AddPracticesetAsLive/>}/>
      <Route exact path="/manage-practiceexam-set" element={<ManagePracticeExamSet/>}/>
      <Route exact path="/practiceexam-batch-group" element={<PracticeExamBatchGroup/>}/>
      <Route exact path="/practiceexam-score" element={<PracticeExamScore/>}/>
      <Route exact path="/practicescoreview/:suid/:practicexmid" element={<PracticeScoreView/>}/>
      <Route exact path="/month-wise-practicescore" element={<MonthWisePracticeScore/>}/>
      
      <Route exact path="/search-student-payment" element={<SearchStudentPayment/>}/>

      <Route exact path="/noticeboard" element={<Noticeboard/>}/>

      <Route exact path="/changepassword" element={<ChangePassword/>}/>

      <Route exact path="/livescoreviewprint" element={<LiveScoreViewPrint/>}/>

      <Route exact path="/cron-settings-reset" element={<CronSettingsReset/>}/>

      <Route exact path="/new-package" element={<NewPackage/>}/>
      <Route exact path="/move-exam-into-package" element={<MoveExamIntoPackage/>}/>
      <Route exact path="/modify-practice-set-name" element={<ModifyPracticeSetName/>}/>

      <Route exact path="/coupon-settings" element={<CouponSettings/>}/>
      <Route exact path="/mock-student" element={<MockStudent/>}/>

    </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
