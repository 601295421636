//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

function NewPackageContent() {

    

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtblod2={
        fontWeight: '700',
        fontSize: '0.8rem'
    };


    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium'
    };

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let limit = 100;
    //let [limit,setLimit]=useState(3);

    

    let [packagelistarr, setPackagelistarr]=useState([]);
    let [parentpackagelist, setParentpackagelist]=useState([]);

    
    let [parentpackagelistarr,setParentpackagelistarr]=useState([]);
    let [parentpackagecount,setParentpackagecount]=useState("");

    let [loader,setLoader]=useState(true);

    let [count, setCount]=useState("");
    async function getPackagelist() {
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/newpackagelist",fd);
        var data=resp.data;
        setPackagelistarr(data.obj);
        setCount(data.datacount);
        setParentpackagelist(data.list);

        setParentpackagelistarr(data.obj2);
        setParentpackagecount(data.parentpackagecount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));

        setLoader(false);
        
    }

    let [isalert,setIsalert]=useState(false);
    let [msg,setMsg]=useState("");

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [packageparentid,setPackageparentid]=useState("");

    // For Modal
    let [newpackagename,setNewpackagename]=useState("");
    let [newpackageid,setNewpackageid]=useState("");
    let [newpackageamount,setNewpackageamount]=useState("");
    let [ispackageamount,setIspackageamount]=useState(false);

    let [ismodalalert,setIsmodalalert]=useState(false);
    let [modalmsg,setModalmsg]=useState("");
    // For Modal
    
    async function addNewpackage() {
        var fd=new FormData();
        fd.append("newpackagename",newpackagename);
        fd.append("packageparentid",packageparentid);
        var resp=await axios.post("https://safalata.net/safalatalara/api/addnewpackage",fd);
        var data=resp.data;
        if (data.addalert==1) {
            getPackagelist();
            setIsnewbtn(true);
            setNewpackagename("");
            setIsalert(false);
        } else if (data.addalert==2) {
            setIsalert(true);
            setMsg(data.msg);
        }
        
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/newpackagelist",fd);
        var data=resp.data;
        setPackagelistarr(data.obj);
        setCount(data.datacount);
        setParentpackagelist(data.list);

        // const total=data.datacount;
        // setPageCount(Math.ceil(total/limit));

        setLoader(false);
    };

    useEffect(()=>{
        getPackagelist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">NEW PACKAGE LIST</h2>

            <div className='container' style={batchbg}>
                {isalert? <div className='text text-danger'>{msg}</div>:''}
                <div className="mt-4 input-group">
                    <select className="form-control form-select" onChange={(ev)=>{
                        setPackageparentid(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }}>
                        <option value="">No Parent Package</option>
                        {parentpackagelist.map((e)=>
                        <option value={e.package_id} key={e.package_id}>{e.package_name}</option>
                        )}
                    </select>
                    <input type="text" className="form-control" placeholder='New Live Exam Set Name' onChange={(ev)=>{
                        setNewpackagename(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                            //addNewbatch();
                        } else {
                            setIsnewbtn(true);
                        }
                    }} value={newpackagename}/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={addNewpackage}>ADD NEW PACKAGE</button>
                    </span>
                </div>
                
                

            </div>

            

            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    NEW PACKAGE LIST - TOTAL COUNT ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Parent Package Name</th>
                                    <th style={txtblod}>Child Package Name</th>
                                    <th style={txtblod}>Total Exam Count</th>
                                    <th className='text-center' style={txtblod}>Package Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {packagelistarr.map((e)=>
                                <tr key={e.package_slno}>
                                <td style={txtblod}>{e.package_parent_id!=0? e.p_package_name : e.package_name}</td>
                                <td style={txtblod2}>{e.package_parent_id!=0? e.package_name:''}</td>
                                {/* {e.package_parent_id!=0? e.p_package_name : '--'} */}
                                
                                <td className='text-center' style={txtblod}>{e.package_parent_id!=0? e.examcount : '--'}</td>

                                {e.status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setNewpackageid(e.package_id);
                                        setNewpackagename(e.package_name);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.liveset_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packageactiveinactive",fd);
                                            var data=resp.data;
                                            getPackagelist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packageactiveinactive",fd);
                                            var data=resp.data;
                                            getPackagelist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        // alert(e.package_id)
                                        if(window.confirm("Do you want to delete this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packagedelete",fd);
                                            var data=resp.data;
                                            if (data.statcode==1) {
                                                getPackagelist();
                                            } else {
                                                alert(data.msg);
                                            }
                                            
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>


            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    PARENT PACKAGE LIST - TOTAL COUNT ( {parentpackagecount} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Parent Package Name</th>
                                    <th style={txtblod}>Package Amount</th>
                                    <th style={txtblod}>Total MCQs</th>
                                    <th className='text-center' style={txtblod}>Package Status</th>
                                    <th className='text-center' style={txtblod}>Action</th>
                                </tr>
                            </thead>                            
                            <tbody>

                            {parentpackagelistarr.map((e)=>
                                <tr key={e.package_slno}>
                                <td style={txtblod}>{e.package_name}</td>     
                                <td style={txtblod}>{e.package_amount}</td>    
                                <td style={txtblod}>{e.total_mcq}</td>                           
                                {e.status==1?
                                <td className='text-center' style={txtgreen}>ACTIVE</td>
                                :<td className='text-center' style={txtred}>INACTIVE</td>}

                                <td className='text-center'><button onClick={async ()=>{
                                        openModal();
                                        setNewpackageid(e.package_id);
                                        setNewpackagename(e.package_name);
                                        setNewpackageamount(e.package_amount);
                                    }} className="btn btn-default"><i className="fas fa-edit" style={iconedit}></i></button>

                                    {e.liveset_status==1?<button onClick={async ()=>{
                                        if (window.confirm("Do you want to inactive this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packageactiveinactive",fd);
                                            var data=resp.data;
                                            getPackagelist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    :
                                    <button onClick={async ()=>{
                                        if (window.confirm("Do you want to active this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packageactiveinactive",fd);
                                            var data=resp.data;
                                            getPackagelist();
                                        }
                                    }} className="btn btn-default"><i className="fas fa-flag" style={iconactiveinactive}></i></button>
                                    }

                                    <button onClick={async ()=>{
                                        // alert(e.package_id)
                                        if(window.confirm("Do you want to delete this package?")) {
                                            var fd=new FormData();
                                            fd.append("package_id",e.package_id);
                                            var resp=await axios.post("https://safalata.net/safalatalara/api/packagedelete",fd);
                                            var data=resp.data;
                                            if (data.statcode==1) {
                                                getPackagelist();
                                            } else {
                                                alert(data.msg);
                                            }
                                            
                                        }
                                    }} className="btn btn-default"><i className="fas fa-times" style={icontimes}></i></button></td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>


            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
                                    
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM MODIFICATION:::
            </div>
            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpackagename(ev.target.value);
                }} value={newpackagename}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>

            <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpackageamount(ev.target.value);
                }} value={newpackageamount}/>
                {ispackageamount?<div className='text text-danger'>required package amount</div>:''}
            </div>

            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var flag=1;

                if (newpackageamount.length==0) {
                    flag=0;
                    setIspackageamount(true);
                } else {
                    setIspackageamount(false);
                }

                if (newpackageamount.length==0) {
                    flag=0;
                    setIspackageamount(true);
                } else {
                    setIspackageamount(false);
                }

                if (flag==1) {
                    setIsmodalalert(false);
                    var fd=new FormData();
                    fd.append("newpackageid",newpackageid);
                    fd.append("newpackagename",newpackagename);
                    fd.append("newpackageamount",newpackageamount);
                    var resp=await axios.post("https://safalata.net/safalatalara/api/packagenameupdate",fd);
                    var data=resp.data;
                    
                    if (data.addalert==1) {
                        setIsOpen(false);
                        getPackagelist();
                    } else {
                        setIsmodalalert(true);
                        setModalmsg("Live Exam Set Already Exist!!")
                    }
                }

                
                                
            }} className="btn btn-primary">Save Changes</button>
            </div>
        </Modal>
        {/* FOr Modal */}
        </>
    );

}

export default NewPackageContent;