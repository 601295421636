import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// import dateFormat from 'dateformat';

const showcheckarr=[];
const packagenamearr=[];
const perpackageamount=[];
let showcount=0;
let sumamount=0.00;
let countpackage=0;

const styles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function StudentPaymentContent() {

    // For Modal
    const customStyles = {
        content: {
          
          top: '55%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    const formgroup2={
        margin: '26px',
        fontWeight: 700
    };

    const formgroup3={
        margin: '10px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setPaidlistarr([]);
        setIsOpen(false);
        // setIsOpen(true);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small',
        width: '10%'
    };
    const txtblod2={
        fontWeight: '700',
        fontSize: 'small',
        width: '20%'
    };
    const txtblod3={
        fontWeight: '700',
        fontSize: 'small',
        width: '30%'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const iconeditblue={
        color: '#3a00c2',
        cursor: 'pointer',
        fontSize: 'large'
    };

    const iconeditdisabled={
        color: '#009127',
        cursor: 'alias',
        fontSize: 'x-large'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-large'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '110%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const div10={
        width: '110%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '70px',
        borderRadius: '10px'
    };

    const selectright={
        paddingRight: '10px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    
    let [count,setCount]=useState("");

    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studpaylistarr, setStudpaylistarr]=useState([]);
    let [loader,setLoader]=useState(false);

    let [changestatus, setChangestatus]=useState("");

    let [batchlistarr,setBatchlistarr]=useState([]);
    let [studlistarr, setStudlistarr]=useState([]);
    let [search,setSearch]=useState("");

    let limit = 50;

    let [btnfilter2,setBtnfilter2]=useState("");

    let [txtstatus,setTxtstatus]=useState("");

    async function getStudpaymentlist() {
        setLoader(true);
        // setBtnfilter("1");
        var btnfilter="1";
        var fd=new FormData();
        fd.append("btnfilter",btnfilter);
        fd.append("pageno",pageno);
        fd.append("limit",limit);
        fd.append("search",search);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data=resp.data;
        setStudpaylistarr(data.obj);
        setCount(data.count);
        const total=data.count;
        setPageCount(Math.ceil(total/limit));

        setBtnfilter2(btnfilter);
        
        if (btnfilter=="1") {
            setTxtstatus("All");
        } else if (btnfilter=="2") {
            setTxtstatus("Active");
        } else if (btnfilter=="3") {
            setTxtstatus("Inactive");
        }
        
        setLoader(false);
    }

    let [monthyeararr,setMonthyeararr]=useState([]);
    let [monthyear,setMonthyear]=useState("");
    let [remark,setRemark]=useState("");
    let [remarkoption,setRemarkoption]=useState(0);

    //today date
    const now = new Date();
    const curdate=dateFormat(now, "yyyy-mm-dd HH:MM:ss");

    let [paidlistarr,setPaidlistarr]=useState([]);


    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("btnfilter",btnfilter2);
        fd.append("limit",limit);
        fd.append("search",search);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
        var data=resp.data;
        setStudpaylistarr(data.obj);
        setCount(data.count);
        const total=data.count;
        setPageCount(Math.ceil(total/limit));

        setLoader(false);
    };

    

    let [studname,setStudname]=useState("");
    let [studmobile,setStudmobile]=useState("");
    let [studenrollid,setStudenrollid]=useState("");
    let [packageplan,setPackageplan]=useState([]);
    
    let [packagename,setPackagename]=useState([]);
    let [showcheck,setShowcheck]=useState([]);

    let [discounttype,setDiscounttype]=useState("");
    let [discount,setDiscount]=useState("");
    let [discountamount,setDiscountamount]=useState(0.00);
    let [totalpackage,setTotalpackage]=useState("");

    let [grandtotal,setGrandtotal]=useState(0.00);
    let [sumtotal,setSumtotal]=useState(0.00);
    const [isMobile, setIsMobile] = useState(false);

    let [packagelist,setPackagelist]=useState([]);
    let [packagecount,setPackagecount]=useState(0);
    let [packageamount,setPackageamount]=useState(0);
    let [couponcode,setCouponcode]=useState("");

    let [couponamount,setCouponamount]=useState(0);

    let [arraycount,setArraycount]=useState(0);
    let [apply,setApply]=useState("Apply");

    
    function updatecart() {
        setPackagelist(showcheckarr);
        setPackagecount(showcount);
        setPackageamount(sumamount);
        setTimeout(()=>{
            console.log(packagelist);
            console.log(showcount);
        },500);
    }

    async function pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal) {
        if (countpackage!=0) {
            var fd=new FormData();
            fd.append("oid",oid);
            fd.append("grandtotal",grandtotal);
            fd.append("packageids",packageids);
            fd.append("enrollid",studenrollid);
            fd.append("countpackage",countpackage);
            fd.append("discounttype",discounttype);
            fd.append("discount",discount);
            fd.append("couponcode",couponcode);
            fd.append("discountamount",discountamount);
            fd.append("sumtotal",sumtotal);
            var resp=await axios.post("https://safalata.net/safalatalara/api/gopackagepayment_byadmin",fd);
            var data=resp.data;
            console.log(data.statcode);

            showcheckarr.splice(0, showcheckarr.length);

            if (data.statcode==1) {
                alert("Payment Successfully Updated");
                setIsOpen(false);
            }

        } else {
            alert("Please select package first");
        }
        
    }

    let [studserach,setStudsearch]=useState("");
    let [isnewbtn2,setIsnewbtn2]=useState(true);
    let [user,setUser]=useState("");


    async function getStudpaymentcheck() {
        var fd=new FormData();
        fd.append("studserach",studserach);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentcheck",fd);
        var data=resp.data;
        if (data.statcode==1) {
            setPackageplan(data.obj);
            setUser(data);
            setStudenrollid(data.enroll_id);
        } else {
            setPackageplan([]);
            setUser("");
            setStudenrollid("");
        }        
    }

    useEffect(()=>{

        getStudpaymentlist();

        updatecart();        

    },[])

    return(
        <>
        {/* <head><title>Student Payment</title></head> */}
        <div className="container-fluid px-4">
            
            {/* <h2 className="mt-3 mb-4">STUDENT PAYMENT</h2> */}

            {/* <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{


                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("search","");
                            fd.append("limit",limit);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
                            var data=resp.data;
                            setStudlistarr(data.obj);
                            setCount(data.datacount);

                            const total=data.datacount;

                            setPageCount(Math.ceil(total/limit));
                            setLoader(false);
                        }
                    }} placeholder='Search Student Name OR Enroll ID'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH</button>
                    </span>
                </div>

            </div> */}
            <div className='container' style={batchbg}>
                <div className='row'>

                    <div className='container' style={batchbg}>
                        <div className="mt-4 input-group" style={div70}>
                            <input type="text" className='form-control' onChange={ async (ev)=>{
                                if (ev.target.value.length>0) {
                                    setSearch(ev.target.value);
                                    setIsnewbtn(false);
                                } else if (ev.target.value=="") {
                                    setIsnewbtn(true);
                                    setLoader(true);
                                    var fd=new FormData();
                                    fd.append("pageno",pageno);
                                    fd.append("search","");
                                    fd.append("limit",limit);
                                    var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                                    var data=resp.data;
                                    setStudpaylistarr(data.obj);
                                    setCount(data.count);
                                    const total=data.count;
                                    setPageCount(Math.ceil(total/limit));
                                    
                                    setLoader(false);
                                }
                            }} placeholder='Search Student Name OR Enroll ID OR Mobile No'/>
                            <span className="input-group-btn">
                                <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudpaymentlist}>SEARCH</button>
                            </span>
                        </div>

                    </div>

                    
                    
                    
                </div>
                

            </div>
            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    
                    {/* MANAGE STUDENT LIST FOR PAYMENT (TOTAL STUDENT : {count}) */}
                    <div className='row'>
                        <div className='col-5'><i className="fas fa-table me-1"></i> PAYMENT HISTORY ({txtstatus} COUNT - {count})</div>
                        <div className='col-4'><button className='btn btn-secondary' onClick={async ()=>{
                            // setBtnfilter("1");
                            //alert(btnfilter);
                            var btnfilter="1";
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("btnfilter",btnfilter);
                            fd.append("pageno",pageno);
                            fd.append("limit",limit);
                            fd.append("search",search);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                            var data=resp.data;
                            
                            setStudpaylistarr(data.obj);
                            setCount(data.count);
                            const total=data.count;
                            setPageCount(Math.ceil(total/limit));

                            setBtnfilter2(btnfilter);
                            
                            if (btnfilter=="1") {
                                setTxtstatus("All");
                            } else if (btnfilter=="2") {
                                setTxtstatus("Active");
                            } else if (btnfilter=="3") {
                                setTxtstatus("Inactive");
                            }
                            
                            setLoader(false);
                        }}>ALL</button> <button className='btn btn-primary' onClick={async ()=>{
                            
                            setLoader(true);
                            var btnfilter="2";
                            var fd=new FormData();
                            fd.append("btnfilter",btnfilter);
                            fd.append("pageno",pageno);
                            fd.append("limit",limit);
                            fd.append("search",search);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                            var data=resp.data;
                            setStudpaylistarr(data.obj);
                            setCount(data.count);
                            const total=data.count;
                            setPageCount(Math.ceil(total/limit));

                            setBtnfilter2(btnfilter);
                            
                            if (btnfilter=="1") {
                                setTxtstatus("All");
                            } else if (btnfilter=="2") {
                                setTxtstatus("Active");
                            } else if (btnfilter=="3") {
                                setTxtstatus("Inactive");
                            }
                            
                            setLoader(false);
                        }}>ACTIVE</button> <button className='btn btn-danger' onClick={async ()=>{
                            // setBtnfilter("3");
                            //alert(btnfilter);
                            setLoader(true);
                            var btnfilter="3";
                            var fd=new FormData();
                            fd.append("btnfilter",btnfilter);
                            fd.append("pageno",pageno);
                            fd.append("limit",limit);
                            fd.append("search",search);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudpaymentlist",fd);
                            var data=resp.data;
                            setStudpaylistarr(data.obj);
                            setCount(data.count);
                            const total=data.count;
                            setPageCount(Math.ceil(total/limit));

                            setBtnfilter2(btnfilter);
                            
                            if (btnfilter=="1") {
                                setTxtstatus("All");
                            } else if (btnfilter=="2") {
                                setTxtstatus("Active");
                            } else if (btnfilter=="3") {
                                setTxtstatus("Inactive");
                            }
                            
                            setLoader(false);
                        }}>INACTIVE</button></div>
                        <div className='col-3'><button className='btn btn-primary' onClick={()=>{
                            showcount=0;
                            sumamount=0.00;
                            
                            setGrandtotal(0.00);

                            setDiscounttype("");
                            setDiscount("");
                            setDiscountamount(0.00);
                            setTotalpackage("");
                            setSumtotal(0.00);
                            setPackagelist([]);
                            setPackagecount(0);
                            setPackageamount(0);
                            setCouponcode("");

                            setPackageplan([]);
                            setUser("");

                            openModal();
                            setModalid(5);
                            setId("");
                            setStudname("");
                            setStudmobile("");
                            setStudenrollid("");


                            setPackagename([]);

                            setShowcheck([]);

                            
                            
                        }}>ADD PAYMENT</button></div>
                    </div>
                    
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod} className='text-center'>Identity</th>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod2}>Enroll ID &amp; Date<br/>City<br/>Mobile</th>
                                    <th style={txtblod2} className='text-center'>Rzr Pay ID</th>
                                    <th style={txtblod} className='text-center'>Payment Date</th>
                                    <th style={txtblod2} className='text-center'>Package Name</th>
                                    {/* <th style={txtblod} className='text-center'>Action</th> */}
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studpaylistarr.map((e)=>


                                
                                <tr key={e.enroll_id} style={{fontSize: 'smaller'}}>
                                    <td style={txtblod} className='text-center'>
                                        {e.pic!=null && e.pic!=""?
                                        <img src={"https://safalata.net/safalatalara/uploadpic/"+e.pic} style={picstyle}/>
                                        :
                                        ''
                                        }
                                    </td>
                                    <td style={txtblod}>{e.name}</td>
                                    <td style={txtblod2}><span style={{color: '#383838',fontWeight: 700, fontSize: '0.8rem'}}>Enroll ID: {e.enroll_id}<br/>Enroll Date: {dateFormat(e.enroll_date, "dd-mm-yyyy")}<br/>City: {e.city}<br/>Mobile: {e.phone}</span>
                                    </td>
                                    <td style={txtblod2} className='text-center'>
                                    <span style={{color: '#000',fontWeight: 700, fontSize: '0.8rem'}}>{e.payment_id}</span>
                                    </td>
                                    <td style={txtblod} className='text-center'>
                                    <span style={{color: '#000',fontWeight: 700, fontSize: '0.7rem'}}>{dateFormat(e.payment_date, "dd-mm-yyyy HH:MM")}</span>
                                    </td>
                                    <td style={txtblod2}>
                                    <span style={{color: '#3a00c2',fontWeight: 700, fontSize: '0.75rem'}}>
                                    {e.total_package==1?
                                        e.package_name
                                    :
                                    
                                    e.total_package>1?
                                        e.package_name                                        
                                    :
                                    ''
                                    }
                                    </span>
                                    </td>
                                    
                                    {/* <td className='text-center'>
                                        
                                    <Link to="" className='btn' onClick={async ()=>{
                                            if(window.confirm("Student Name: "+e.name+"\nEnroll ID: "+e.enroll_id+"\nPaid But No Payment Id Created.\nPlease check payment id and Allow for Payment??")) {
                                                var oid = Math.random().toString(16).slice(2)
                                                var amount = 300;
                                                alert(amount);
                                                var fd=new FormData();
                                                fd.append("oid",oid);
                                                fd.append("amt",amount);
                                                fd.append("enrollid",e.enroll_id);
                                                alert(amount);
                                                var resp=await axios.post("https://safalata.net/safalatalara/api/setpaymentlist",fd);
                                                var data=resp.data;
                                                getStudpaymentlist();
                                            }
                                            }}><i class="fas fa-edit" style={iconeditblue}></i>
                                        </Link>
                                    
                                    </td> */}
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>
            


            {/* For Modal */}
        
        {modalid==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            // onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PAYMENT UPDATE:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME : {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS : {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select className="form-control form-select" onChange={(ev)=>{
                    setMonthyear(ev.target.value);
                    if (ev.target.value.length>0 && remark.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Payment Month----</option>
                    {monthyeararr.map((e)=>
                    <option key={e.payment_month_id} value={e.payment_month}>{e.payment_month}</option>
                    )}
                    
                
                </select>
            </div>

            <div className="form-group" style={formgroup}>
                <select className="form-control form-select" onChange={(ev)=>{
                    setRemark(ev.target.value);
                    if (ev.target.value.length>0 && monthyear.length>0) {
                        if (ev.target.value=="Paid") {
                            setRemarkoption(1);
                        } else if (ev.target.value=="Due but allowed") {
                            setRemarkoption(2);
                        } else if (ev.target.value=="Exemption") {
                            setRemarkoption(3);
                        } else {
                            setRemarkoption(0);
                        }
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Payment Remark----</option>
                    <option value="Paid">1. Paid</option>
                    <option value="Due but allowed">2. Due but allowed</option>
                    <option value="Exemption">3. Exemption</option>                
                </select>
            </div>

            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("enrollid",id);
                fd.append("monthyear",monthyear);
                fd.append("curdate",curdate);
                fd.append("remark",remark);
                fd.append("remarkoption",remarkoption); //ADDED
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudpaymentmonthyear",fd);
                var data=resp.data;
                setIsnewbtn(true);
                setRemark("");
                setMonthyear("");
                setMonthyeararr([]);
                setIsOpen(false);       
                getStudpaymentlist();
                       
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>

            <button onClick={closeModal} className="btn btn-primary" style={{marginLeft: 10}}>Close</button>
            </div>
            
        </Modal>
        :''}
        {modalid==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT PAID LIST:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME : {name}
            </div>
            
            <div className="form-group" style={formgroup}>
                CURRENT STATUS : {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                PAID LIST : 
                {paidlistarr.map((a)=>
                    <p key={a.payment_list_id} style={{margin: '5px', color: '#01691d'}}>&bull; {a.payment_list} [{dateFormat(a.payment_date, "dd-mm-yyyy HH:MM TT")}]</p>
                )}
            </div>

            <div className="form-group" style={formgroup}>
            <button onClick={()=>{
                setIsOpen(false);       
            }} className="btn btn-primary">Close</button>
            </div>
            
        </Modal>
        :''}

        {modalid==3?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup}>
                :::STUDENT STATUS:::
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            <div className='form-group' style={formgroup}>
                NAME - {name}
            </div>
            <div className="form-group" style={formgroup}>
                CURRENT STATUS - {status==0?<span style={txtgrey}>PENDING</span>:''}{status==1?<span style={txtgreen}>ACTIVE</span>:''}{status==2?<span style={txtred}>INACTIVE</span>:''}
            </div>
            
            <div className="form-group" style={formgroup}>
                <select value={changestatus} className="form-control" onChange={(ev)=>{
                    setChangestatus(ev.target.value);
                    if (ev.target.value.length>0) {
                        setIsnewbtn(false);
                    } else {
                        setIsnewbtn(true);
                    }
                }}>
                    <option value="">----Select Status----</option>
                    <option value="0">Pending</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                </select>
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                var fd=new FormData();
                fd.append("id",id);
                fd.append("status",changestatus);
                var resp=await axios.post("https://safalata.net/safalatalara/api/updatestudstatus",fd);
                var data=resp.data;
                setIsOpen(false);       
                getStudpaymentlist()         
            }} className="btn btn-primary" disabled={isnewbtn}>Save Changes</button>
            </div>
            
        </Modal>
        :''}

        {modalid==5?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            
            <div className='form-group text-center' style={formgroup2}>
                <div className="mt-1 input-group" style={div10}>
                <input type="text" className='form-control' onChange={ async (ev)=>{
                    if (ev.target.value.length>0) {
                        setStudsearch(ev.target.value);
                        setIsnewbtn2(false);
                    } else if (ev.target.value=="") {
                        setIsnewbtn2(true);
                    }
                }} placeholder='Search By Enroll ID/Mobile'/>
                <span className="input-group-btn">
                    <button className="btn btn-info" type="button" disabled={isnewbtn2} onClick={getStudpaymentcheck}>SEARCH</button>
                </span>
                </div>
            </div>

            <div className='form-group' style={formgroup3}>
                NAME - {user.name}
            </div>
            <div className='form-group' style={formgroup3}>
                ENROLL ID / MOBILE - {user.enroll_id} / {user.phone}
            </div>

            <div className='form-group'>

            <Box sx={{ flexGrow: 1 }}>

            {packageplan.map((e)=>


            // {e.packagevaliditycount==0? "ddd" : ''}

            <Grid container spacing={0} key={e.package_id} mb={4} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
            
                <Grid item xs={12} spacing={0.8} sx={{background: '#e3f2fd'}}>
                    <Item sx={{fontSize:  '0.8rem', textAlign: 'left', fontWeight: 900, border: 0, pt: 0}}>
                        <FormControlLabel control={<Checkbox {...label} color="info" disabled={e.expiry_days<8? '' : 'true'} defaultChecked={e.expiry_days<8? '' : 'true'} />} onChange={(c)=>{
                        // disabled={e.packagevaliditycount>0? 'true' : ''}
                        // defaultChecked={e.packagevaliditycount>0? 'true' : ''}
                        setId("");
                        setDiscounttype("");
                        setDiscount("");
                        setCouponcode("");
                        setDiscountamount(0.00);

                        if (c.target.checked==true) {                                            

                            showcheckarr.push(e.package_id);

                            showcount=parseInt(showcount)+parseInt(1);
                            sumamount=parseFloat(sumamount)+parseFloat(e.package_amount);
                            packagenamearr.push(e.package_name);
                            perpackageamount.push(e.package_amount);
                            setGrandtotal(sumamount);
                            setSumtotal(sumamount);
                            setPackagename(packagenamearr);

                            setShowcheck(showcheckarr);

                        } else {
                            const idx = showcheckarr.indexOf(e.package_id);
                            showcheckarr.splice(idx, idx !== -1 ? 1 : 0);

                            showcount=parseInt(showcount)-1;
                            sumamount=parseFloat(sumamount)-parseFloat(e.package_amount);
                            const idx2 = packagenamearr.indexOf(e.package_name);
                            packagenamearr.splice(idx2, idx2 !== -1 ? 1 : 0);

                            const idx3 = perpackageamount.indexOf(e.package_amount);
                            perpackageamount.splice(idx3, idx3 !== -1 ? 1 : 0);
                            setGrandtotal(sumamount);
                            setSumtotal(sumamount.toFixed(2));
                            setPackagename(packagenamearr);

                            setShowcheck(showcheckarr);

                        }

                        updatecart();
                        
                    }}/> {isMobile && e.package_name.length>=15? e.package_name.substr(0,18) : e.package_name} ({e.package_amount})</Item>
                    {e.packagevaliditycount>0 && e.expiry_days>=0 && e.expiry_days<8?
                        <Typography sx={{fontSize:  '0.8rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Next Due Date on {e.expiry_date}</Typography>
                    :
                    e.packagevaliditycount>0 && e.expiry_days>=8?

                        <Typography sx={{fontSize:  '0.8rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Next Due Date on {e.expiry_date}</Typography>
        
                    :
                    e.packagevaliditycount==0 && e.plantakencount>0?

                        <Typography sx={{fontSize:  '0.8rem', textAlign: 'left', fontWeight: 700, color: 'red'}}>Plan Expired on {e.expiry_date}</Typography>

                    :
                    ''
                    }
                </Grid>

                

                
            </Grid>
            )}  


            {/* <Grid container spacing={0} mb={1} sx={{borderRadius: 2, background: '#e3f2fd', lineHeight: 1, pl:0.5,pr:0, pt: 0, pb: 0 }}>
                <Grid item xs={7}>
                    <Item sx={{fontSize:  '0.8rem', textAlign: 'right', fontWeight: 700}}>
                    TOTAL AMOUNT
                    </Item>
                </Grid>
                <Grid item xs={5}>
                    <Item sx={{fontSize:  '0.8rem', textAlign: 'right', fontWeight: 700}}>
                    Rs. {grandtotal.toFixed(2)}
                    </Item>
                </Grid>
            </Grid>  */}


            </Box>

            <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>


                <Typography align="center" variant="h6" component="h6" sx={{fontSize: '1rem', pt: 1.1, pb: 1, color: '#fff', backgroundColor: '#0000ff', cursor: 'pointer'}}  onClick={()=>{
                    var enroll_id=localStorage.getItem("enrollid");
                    if (showcount==0) {
                        //PAYMENT GATEWAY CODE
                        var packageids=showcheck;
                        var oid = "";
                        countpackage=0;                                    
                        pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal);
                    } else {
                        //PAYMENT GATEWAY CODE
                        var packageids=showcheck;
                        var oid = Math.random().toString(16).slice(2);
                        countpackage=showcount;                                    
                        pay_online(oid,grandtotal,packageids,studenrollid,countpackage,discounttype,discount,couponcode,discountamount,sumtotal);
                    }
                    }}>
                    PAY NOW {grandtotal!=0 || grandtotal!=0.00? 'Rs.'+grandtotal : ''}
                </Typography>
            </Paper>

            </div>

            {/* <div className="form-group" style={formgroup}>
            <button onClick={()=>{
                    setIsOpen(false);
            }} className="btn btn-primary">Close</button>
            </div> */}
            
        </Modal>
        :''}

        {/* FOr Modal */}
            

        </div>


        
        </>
    );

}

export default StudentPaymentContent;