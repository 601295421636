//import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader

//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

// For Import QA Loader
import {data} from "../include/Loader";

import "../loader.css";
// For Import QA Loader

import $ from "jquery";

function MoveExamIntoPackageContent() {

    

    // For Modal
    const customStyles = {
        content: {
          marginTop: '100px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const MODAL_STYLES = {
        // position: "absolute",
        position: "relative",
        backgroundColor: "#FFF",
        padding: "15px",
        // zIndex: "2000",
        // width: "100%",
        width: '900px',
        height: '14000px',
        borderRadius: ".5em"
      };
      
      const OVERLAY_STYLE = {
        position: "fixed",
        display: "flex",
        justifyContent: "center",
        top: "50",
        left: "50",
        width: "100%",
        height: "2000px",
        backgroundColor: "rgba(0,0,0, .8)",
        // zIndex: "2000",
        overflowY: "auto"
      };
      

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    const formgrouptitle={
        margin: '15px',
        fontWeight: 700,
        fontSize: 'large'
    };

    let subtitle;
    const [modalIsOpenLive, setIsOpenLive] = React.useState(false);
    const [modalIsOpenPractice, setIsOpenPractice] = React.useState(false);

    // For Loader Modal
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // For Loader Modal


    function openModalLive() {
        setIsOpenLive(true);
    }
    function closeModalLive() {
        setIsOpenLive(false);
    }

    function openModalPractice() {
        setIsOpenPractice(true);
    }
    function closeModalPractice() {
        setIsOpenPractice(false);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }


    const [modalIsOpenShuffle, setModalIsOpenShuffle] = React.useState(false);

    function openModalShuffle() {
        setModalIsOpenShuffle(true);
    }
    function closeModalShuffle() {
        setModalIsOpenShuffle(false);
    }

    function afterOpenShuffleModal() {
        subtitle.style.color = '#f00';
    }
    
    let [deletepackageid,setDeletepackageid]=useState("");
    let [deletepackagename,setDeletepackagename]=useState("");
    let [practicesetid,setPracticesetid]=useState("");
    let [practicesetname,setPracticesetname]=useState("");
    let [currentshuffle,setCurrentshuffle]=useState("");
    let [newshuffle,setNewshuffle]=useState("");
    let [ismodalbtn,setIsmodalbtn]=useState(true);
    let [ismodalalert,setIsmodalalert]=useState(false);
    let [qidcount,setQidcount]=useState("");
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'medium',
        width: '5%'
    };

    const txtblod1={
        fontWeight: '700',
        fontSize: 'medium',
        width: '11%'
    };

    const txtblod2={
        fontWeight: '700',
        fontSize: 'medium',
        width: '40%'
    };

    const txtblod4={
        fontWeight: '700',
        fontSize: 'medium',
        width: '11%'
    };

    const txtblod5_1={
        fontWeight: '700',
        fontSize: '0.8rem',
        width: '20%'
    };

    const txtblod5={
        fontWeight: '700',
        fontSize: '0.8rem',
        width: '25%'
    };

    const txtblod6={
        fontWeight: '700',
        fontSize: '0.8rem',
        width: '65%'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.9)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconactiveinactive={
        color: '#995900',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const icontimes={
        color: '#c70000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700',
        fontSize: 'medium',
        width: '12%'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700',
        fontSize: 'medium',
        width: '12%'
    };

    const txtblue={
        color: '#0e007a',
        fontWeight: '700',
        fontSize: 'small',
        width: '12%'
    };

    let [isnewbtn,setIsnewbtn]=useState(true);

    let [packagelistarr, setPackagelistarr]=useState([]);
    let [packageid, setPackageid]=useState("");
    let [loader,setLoader]=useState(true);

    async function getPackagelist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/packagegrouplist");
        var data=resp.data;
        setPackagelistarr(data);
        setLoader(false);
    }

    let [exampackagelistarr, setExampackagelistarr]=useState([]);
    let [loader2,setLoader2]=useState(true);
    async function exampackagelist() {
        setLoader2(true);
        var resp=await axios.get("https://safalata.net/safalatalara/api/exampackagelist");
        var data=resp.data;
        setExampackagelistarr(data.obj);
        setLoader2(false);
        

        console.log("=======>",data.obj)
    }

    async function addLiveexam() {
        openModalLive();
    }

    async function addPracticeexam() {
        openModalPractice();
    }

    let [batchlistarr,setBatchlistarr]=useState([]);
    let [loadermodal,setLoadermodal]=useState(true);
    let [totalcount,setTotalcount]=useState("");

    async function getNewpracticesetlist() {
        setLoadermodal(true);
        openModal();
        var fd=new FormData();
        fd.append('courseid',course);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getnewpracticesetlist",fd);
        var data=resp.data;
        setBatchlistarr(data.arr);
        setTotalcount(data.count);
        setLoadermodal(false);
        closeModal();

        // openModalLive();
    }

    async function live_push_to_package() {
        var chk_value = [];
        $("input[name='chkallot']:checked").each(function() {
            chk_value.push(this.value);
        });
        var numberOfchkallot = $("input[name='chkallot']:checked").length; //$('input:checkbox:checked').length;
        // alert(numberOfchkallot);

        var fd=new FormData();
        fd.append('packageid',packageid);
        fd.append('chk_value',chk_value);
        fd.append('total_chk',numberOfchkallot);
        // alert(numberOfchkallot)
        // fd.append('old_filter','Live'); //insert from 1000 id
        var resp=await axios.post("https://safalata.net/safalatalara/api/push_practice_set_to_package",fd);
        var data=resp.data;

        alert(data.msg);

        if (numberOfchkallot>0) {
            getNewpracticesetlist();
            exampackagelist();
        }
        
    }

    let [courselist,setCourselist]=useState([]);
    let [course,setCourse]=useState([]);

    async function newcourselist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newcourselistasc");
        var data=resp.data;
        setCourselist(data);
    }

    useEffect(()=>{
        getPackagelist();
        exampackagelist();
        newcourselist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">Move Exam into Package</h2>

            <div className='container' style={batchbg}>
                
                <div className="mt-4 input-group form-group form-inline">
                    <label style={{marginRight: '10px', paddingTop: '4px', fontSize: '14pt'}}>Select Package</label>
                    <select className="form-control form-control-large form-select large" onChange={(ev)=>{
                        setPackageid(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsnewbtn(false);
                        } else {
                            setIsnewbtn(true);
                        }
                    }}>
                        <option style={{fontSize: '14pt',fontWeight: 500}} value="">---Select Package---</option>
                        {packagelistarr.map((p)=>
                        <optgroup style={{fontSize: '14pt',fontWeight: 500}} label={p.ppackage_name} key={p.ppackage_id}>
                            {p.child.map((c)=>
                            <option style={{fontSize: '14pt',fontWeight: 500}} key={c.package_id} value={c.package_id}>{'->'}{c.package_name}</option>
                            )}
                        </optgroup>
                        )}
                    </select>
                    
                   
                    
                </div>
                
            </div>

      
            <div className="mt-4 input-group form-group form-inline" style={formgroup}>
            <label style={{marginRight: '10px', paddingTop: '4px', fontSize: '14pt'}}>:::PRACTICE SET LIST::: (COUNT TOTAL : {totalcount})</label>

                {/* <div style={{marginLeft: '20px'}}> */}
                <select className="form-control form-select large" onChange={(ev)=>{
                        setCourse(ev.target.value);
                        // if (ev.target.value.length>0) {
                        //     setIsnewbtn(false);
                        // } else {
                        //     setIsnewbtn(true);
                        // }
                    }}>
                        <option style={{fontSize: '14pt',fontWeight: 500}} value="">---Select Course---</option>
                        {courselist.map((p)=>
                            <option style={{fontSize: '14pt',fontWeight: 500}} key={p.course_slno} value={p.course_slno}>{p.course_name}</option>
                        )}
                    </select>
                {/* </div> */}

                <div style={{marginLeft: '20px'}}>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getNewpracticesetlist}>PRACTICE SET LIST</button>
                    </span>
                </div>
            </div>
            

            <div className="table-responsive">
                <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th className='text-center' style={txtblod}></th>
                            <th className='text-center' style={txtblod4}>EXAM ID</th>
                            <th style={txtblod2}>EXAM NAME</th>
                            <th style={txtblod1}>QID COUNT</th>
                            <th className='text-center' style={txtblod1}>SHUFFLE</th>
                        </tr>
                    </thead>
                    
                    <tbody>
                    
                    {batchlistarr.map((e)=>
                    
                        <tr key={e.practiceset_slno}>
                            <td className='text-center' style={txtblod}>
                            <input type="checkbox" name="chkallot" value={e.practiceset_slno} class="form-check-input" onChange={(ev)=>{
                                console.log(e.qid);
                            }} />
                            </td>
                            <td className='text-center' style={txtblod4}>{e.practiceset_slno}</td>
                            <td style={txtblod2}>{e.practiceset_name}</td>
                            <td style={txtblod1}>{e.qidcount}</td>
                            {e.practiceexamsetselection_shuffle==0?
                            <td className='text-center' style={txtred}>NON SHUFFLE</td>
                            :e.practiceexamsetselection_shuffle==1?
                            <td className='text-center' style={txtgreen}>FULL SHUFFLE</td>
                            :
                            <td className='text-center' style={txtblue}>PARTIAL SHUFFLE</td>
                            }
                        </tr>
                    )}

                        <tr>
                            <td colSpan={5} className='text-center'><button className='btn btn-success' onClick={live_push_to_package}>PUSH TO PACKAGE</button></td>
                        </tr>
                            
                    </tbody>
                </table>
            </div>


            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    Move Exam into Package - TOTAL COUNT
                </div>
                <div className="card-body">
                    
                {!loader2?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod5_1}>Parent Package Name</th>
                                    <th style={txtblod5}>Child Package Name</th>
                                    <th style={txtblod6}>Exam List</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {exampackagelistarr.map((e)=>
                                <tr key={e.package_id}>
                                    <th style={txtblod5_1}>{e.p_package_name}</th>
                                    <th style={txtblod5}>{e.package_name}</th>
                                    <th style={txtblod6}>
                                    {/* {e.examlist.map((m)=>{ */}
                                    {e.child.map((m)=>
                                    <div style={{display: 'inline-block', border: '1px solid red', borderRadius: '5px', padding: '3px 3px', margin: '5px', fontSize: 'small', cursor: 'pointer'}} key={m.practiceset_slno} onClick={async ()=>{
                                        // var a=window.confirm("Want to remove exam '"+m.practiceset_name+"' from package '"+e.package_name+"'?");
                                        // if (a) {
                                        //     var fd=new FormData();
                                        //     fd.append('package_id',e.package_id);
                                        //     fd.append('practiceset_slno',m.practiceset_slno);
                                        //     var resp=await axios.post("https://safalata.net/safalatalara/api/pop_practice_set_from_package",fd);
                                        //     var data=resp.data;

                                        //     getNewpracticesetlist();
                                        //     exampackagelist();

                                        //     $("input[name='chkallot']:checked").each(function() {
                                        //         $('input:checkbox').removeAttr('checked');
                                        //     });
                                        // }
                                        setDeletepackageid(e.package_id);
                                        setDeletepackagename(e.package_name);
                                        setQidcount(m.qidcount);
                                        setCurrentshuffle(m.practiceexamsetselection_shuffle);
                                        setPracticesetid(m.practiceset_slno);
                                        setPracticesetname(m.practiceset_name);
                                        openModalShuffle();
                                        }}>
                                        {m.practiceset_name} <i class="fas fa-times" style={{color: '#c70000', cursor: 'pointer', fontSize: 'small'}}></i>
                                    </div>
                                    )}
                                    </th>                                    
                                </tr>                               
                            )}
                            {/* {packagelistarr.map((p)=>
                            <optgroup style={{fontSize: '14pt',fontWeight: 500}} label={p.ppackage_name} key={p.ppackage_id}>
                                {p.child.map((c)=>
                                <option style={{fontSize: '14pt',fontWeight: 500}} key={c.package_id} value={c.package_id}>{'->'}{c.package_name}</option>
                                )}
                            </optgroup>
                            )} */}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
                      
            </div>

        </div>


        {/* For Modal */}
        <Modal
            isOpen={modalIsOpenLive}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModalLive}
            // style={customStyles}
            style={{top: '500px'}}
            // contentLabel="Example Modal"
            // backdrop="static"
            // keyboard={false}
            // fullscreen={true}
        >
        <div style={OVERLAY_STYLE}>          
            <div className="form-group" style={formgroup}>
                :::LIVE EXAM LIST:::
            </div>
            <div className="table-responsive11" style={MODAL_STYLES}>
                <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                        {/* <th className='text-center' style={txtblod}>GROUP ID</th> */}
                            <th className='text-center' style={txtblod}>EXAM ID</th>
                            <th style={txtblod}>EXAM NAME</th>
                            {/* <th className='text-center' style={txtblod}>BATCH LIST</th> */}
                            {/* <th className='text-center' style={txtblod}>EXAM DATE TIME</th> */}
                            {/* <th className='text-center' style={txtblod}>STATUS</th> */}
                            <th className='text-center' style={txtblod}>SHUFFLE</th>
                            {/* <th className='text-center' style={txtblod}>ACTION</th> */}
                        </tr>
                    </thead>
                    
                    <tbody>
                    
                    {batchlistarr.map((e)=>
                    
                        <tr key={e.liveset_slno}>
                            
                            <td className='text-center' style={txtblod}>{e.liveset_slno}</td>
                            <td style={txtblod}>{e.liveset_name}</td>
                            
                            {/* {e.liveexamsetselection_status==1?
                            <td className='text-center' style={txtgreen}>EXAM SOON</td>
                            :e.liveexamsetselection_status==2?<td className='text-center' style={txtblue}>EXAM OPEN</td>
                            :<td className='text-center' style={txtred}>EXAM CLOSED</td>} */}
                            {e.liveexamsetselection_shuffle==0?
                            <td className='text-center' style={txtred}>NON SHUFFLE</td>
                            :e.liveexamsetselection_shuffle==1?
                            <td className='text-center' style={txtgreen}>FULL SHUFFLE</td>
                            :
                            <td className='text-center' style={txtblue}>PARTIAL SHUFFLE</td>
                            }

                            <td className='text-center'>
                            
                            </td>
                        </tr>
                    
                    )}
                    
                            
                    </tbody>
                </table>
            </div>
        </div>
        </Modal>

        <Modal
            isOpen={modalIsOpenPractice}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModalPractice}
            style={customStyles}
            contentLabel="Example Modal"
            backdrop="static"
            keyboard={false}
        >

                                    
            <div className="form-group" style={formgroup}>
                :::PRACTICE EXAM LIST:::
            </div>
            {/* <div className="form-group" style={formgroup}>
                <input type="text" className="form-control" onChange={(ev)=>{
                    setNewpackagename(ev.target.value);
                }} value={newpackagename}/>
                {ismodalalert?<div className='text text-danger'>{modalmsg}</div>:''}
            </div>
            <div className="form-group" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("newpackageid",newpackageid);
                fd.append("newpackagename",newpackagename);
                var resp=await axios.post("https://safalata.net/safalatalara/api/packagenameupdate",fd);
                var data=resp.data;
                
                if (data.addalert==1) {
                    setIsOpen(false);
                    getPackagelist();
                } else {
                    setIsmodalalert(true);
                    setModalmsg("Live Exam Set Already Exist!!")
                }
                                
            }} className="btn btn-primary">Save Changes</button>
            </div> */}
        </Modal>
        {/* FOr Modal */}

        {/* For Modal */}
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div className="main">
                <div className="loaderWrapper">
                    {data.map((loader, index) => (
                    <div key={loader.name + index} className="loaderBox">
                        <loader.Component {...loader.props} />
                    </div>
                    ))}
                    {/* <div className="loaderBox">
                        <BallTriangle color="#00BFFF" height={80} width={80} />
                    </div> */}
                </div>
            </div>                        
            

        </Modal>
        {/* FOr Modal */}


        <Modal
            isOpen={modalIsOpenShuffle}
            onAfterOpen={afterOpenShuffleModal}
            onRequestClose={closeModalShuffle}
            style={customStyles}            
            // contentLabel="Example Modal"
        >

            <div className="form-group text-center" style={formgrouptitle}>
                :::PRACTICE SET SHUFFLE:::
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXAM ID : {practicesetid}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label>EXAM NAME : {practicesetname}</label>
            </div>
            <div className="form-group" style={formgroup}>
                <label style={{paddingTop:'5px'}}>EXISTING SHUFFLE : 
                {currentshuffle==0?
                    ' Non Shuffle'
                : currentshuffle==1?
                    ' Full Shuffle'
                :
                    ' Partial Shuffle'
                }
                </label>
            </div>
            <div className="form-group" style={formgroup}>
                <label style={{paddingTop:'5px'}}>UPDATE SHUFFLE : </label>
                <div class="form-check" style={{paddingTop:'5px'}}>
                    <input class="form-check-input" type="radio" name="radio" value="0" onChange={(ev)=>{
                        setNewshuffle(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsmodalbtn(false);
                        } else {
                            setIsmodalbtn(true);
                        }
                    }} />
                    <label class="form-check-label">
                    Non Shuffle
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="radio" value="1" onChange={(ev)=>{
                        setNewshuffle(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsmodalbtn(false);
                        } else {
                            setIsmodalbtn(true);
                        }
                    }} />
                    <label class="form-check-label">
                    Full Shuffle
                    </label>
                </div>
                {qidcount==100?
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="radio" value="2" onChange={(ev)=>{
                        setNewshuffle(ev.target.value);
                        if (ev.target.value.length>0) {
                            setIsmodalbtn(false);
                        } else {
                            setIsmodalbtn(true);
                        }
                    }} />
                    <label class="form-check-label">
                    Partial Shuffle
                    </label>
                </div>
                :''}
            </div>


            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsmodalalert(false);
                var fd=new FormData();
                fd.append("practicesetid",practicesetid);
                fd.append("newshuffle",newshuffle);
                var resp=await axios.post("https://safalata.net/safalatalara/api/practicesetshuffleupdate",fd);
                var data=resp.data;
                setIsmodalalert(true);
                setIsmodalbtn(true);

                setTimeout(()=>{
                    // setIsOpen(false);
                    setModalIsOpenShuffle(false);
                    exampackagelist();
                }, 100);                
                }} className="btn btn-primary" disabled={ismodalbtn}>Save Changes</button> <br/><br/>
                <button onClick={async ()=>{
                    var a=window.confirm("Want to remove exam '"+practicesetname+"' from package '"+deletepackagename+"'?");
                    if (a) {
                        var fd=new FormData();
                        fd.append('package_id',deletepackageid);
                        fd.append('practiceset_slno',practicesetid);
                        var resp=await axios.post("https://safalata.net/safalatalara/api/pop_practice_set_from_package",fd);
                        var data=resp.data;

                        getNewpracticesetlist();
                        setModalIsOpenShuffle(false);
                        exampackagelist();

                        $("input[name='chkallot']:checked").each(function() {
                            $('input:checkbox').removeAttr('checked');
                        });
                    }
                }} className="btn btn-danger">DELETE THIS SET</button>
                {ismodalalert?<div className='text text-success'>Schedule Update</div>:''}
            </div>
        </Modal>
        </>
    );

}

export default MoveExamIntoPackageContent;