import {Link} from 'react-router-dom';

import {useEffect, useState} from 'react';
import axios from 'axios';

import dateFormat from 'dateformat';

import React from 'react';

// For Modal
import Modal from 'react-modal';
// For Modal

// For Facebook Loader
import TableLoader from '../include/TableLoader';
// For Facebook Loader


//React Pagination
import ReactDOM from 'react-dom';
import ReactPaginate from 'react-paginate';
//React Pagination

import {useNavigate} from "react-router-dom";

function MockStudentContent() {

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgrouptitle={
        margin: '0px',
        fontWeight: 700,
        backgroundColor: 'rgba(9,9,9,0.2)'
    };

    const formgroup={
        margin: '20px',
        fontWeight: 700
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    const txtblod={
        fontWeight: '700',
        fontSize: 'small'
    };

    const txtblodcenter={
        fontWeight: '700',
        fontSize: 'medium',
        textAlign: 'center'
    };

    const batchbg={
        backgroundColor: 'rgba(240,240,240,0.4)',
        paddingTop: '5px',
        paddingBottom: '30px',
        paddingLeft: '30px',
        paddingRight: '30px',
        borderRadius: '10px',
        border: '1px solid #d4d4d4'
    };


    const tableheadline={
        fontSize: '1rem',
        fontWeight: 700,
    };

    const iconedit={
        color: '#009127',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconkey={
        color: '#d49b00',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrophy={
        color: '#0004d4',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const icontrash={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'x-small'
    };

    const iconpaid={
        color: '#d40000',
        cursor: 'pointer',
        fontSize: 'medium'
    };

    const iconscrew={
        color: '#d40000',
        cursor: 'pointer'
    };

    const div70={
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto'
    };

    const txtgreen={
        color: '#01691d',
        fontWeight: '700'
    };

    const txtred={
        color: '#c70000',
        fontWeight: '700'
    };

    const txtgrey={
        color: '#787878',
        fontWeight: '700'
    };

    const picstyle={
        width: '50px'
    };

    const picstylelarge={
        width: '300px'
    };

    // For Modal Check
    let [modalid,setModalid]=useState(1);
    // For Modal Check

    let [isnewbtn,setIsnewbtn]=useState(true);
    let [search,setSearch]=useState("");

    let [batchlistarr, setBatchlistarr]=useState([]);
    let [batchslno, setBatchslno]=useState(0);

    let [name,setName]=useState("");
    let [id,setId]=useState("");
    let [status,setStatus]=useState("");
    let [batchnm,setBatchnm]=useState("");

    let [changestatus, setChangestatus]=useState("");
    let [newpassword, setNewpassword]=useState("");
    let [ispassworderr, setIspassworderr]=useState(false);
    
    let [count,setCount]=useState("");

    // let [pageno,setPageno]=useState("");
    let [pageno,setPageno]=useState(1);
    let [pageCount,setPageCount]=useState("");
    let [studlistarr, setStudlistarr]=useState([]);
    let [loader,setLoader]=useState(false);

    let [pic,setPic]=useState("");
    
    //let [limit,setLimit]=useState(3);

    let limit = 50;

    async function getStudlist() {
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",pageno);
        fd.append("search",search);
        fd.append("limit",limit);
        //alert(pageno);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getmockstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);

        const total=data.datacount;
        setPageCount(Math.ceil(total/limit));
        
        setLoader(false);
    }

    async function getBatchlist() {
        var resp=await axios.get("https://safalata.net/safalatalara/api/newbatchlist");
        var data=resp.data;
        setBatchlistarr(data);
    }

    const handlePageClick = async (event) => {
        let page=event.selected+1;
        setLoader(true);
        var fd=new FormData();
        fd.append("pageno",page);
        fd.append("search",search);
        fd.append("limit",limit);
        var resp=await axios.post("https://safalata.net/safalatalara/api/getmockstudlist",fd);
        var data=resp.data;
        setStudlistarr(data.obj);
        setCount(data.datacount);
        setLoader(false);
    };

    
    // // TIME INTERVAL COUNTDOWN
    //       // const [timerday,setTimerday]=useState("00");
    //       // const [timerhour,setTimerhour]=useState("00");
    //       // const [timermin,setTimermin]=useState("00");
    //       // const [timersec,setTimersec]=useState("00");

    //       const [datetimeinterval,setDateTimeInterval]=useState("");

    //       // const [enddate,setEnddate]=useState("");

    //       // let x=0;

    //       let interval = useRef();

    //       const startTimer = (endeddate) => {
    //           const coundownDate = new Date(endeddate).getTime();

    //           interval=setInterval(()=>{
    //               const now = new Date().getTime();
    //               const distance = coundownDate-now;

    //               const days=Math.floor(distance / (1000*60*60*24));
    //               const hours=Math.floor((distance % (1000*60*60*24) / (1000*60*60)));
    //               const mins=Math.floor((distance % (1000*60*60)) / (1000*60));
    //               const secs=Math.floor((distance % (1000*60)) / 1000);

    //               if (distance < 0) {
    //                   //stop timer code
    //                   clearInterval(interval);
    //                   setDateTimeInterval("Schedule End");
    //                   // setIsradio(true);
    //                   // setIsskipbtn(true);
    //                   // autosubmitscore();
    //                   // setIsbtn(false);

    //                   // logintokencheck();




                      
    //                   // setBtnpay(false);

    //               } else {
    //                   // setDateTimeInterval(days+"d "+hours+"h "+mins+"m "+secs+"s");
    //                   setDateTimeInterval(days+" Days");
    //                   // setIsradio(false);
    //                   // setIsbtn(true);

    //                   // logintokencheck();

    //                   // console.log(days);

    //                   if (days<8) {
    //                     setBtnpay(false);
    //                   }

    //               }
              
    //           }, 1000)
    //       };
    //       // TIME INTERVAL COUNTDOWN


    useEffect(()=>{
        getStudlist();
        // setPageno(1);
        getBatchlist();
    },[])

    return(
        <>
        <div className="container-fluid px-4">
            
            <h2 className="mt-3 mb-4">MOCK STUDENT</h2>

            <div className='container' style={batchbg}>
                <div className="mt-4 input-group" style={div70}>
                    <input type="text" className='form-control' onChange={ async (ev)=>{
                        if (ev.target.value.length>0) {
                            setSearch(ev.target.value);
                            setIsnewbtn(false);
                        } else if (ev.target.value=="") {
                            setIsnewbtn(true);
                            setLoader(true);
                            var fd=new FormData();
                            fd.append("pageno",pageno);
                            fd.append("search","");
                            fd.append("limit",limit);
                            var resp=await axios.post("https://safalata.net/safalatalara/api/getstudlist",fd);
                            var data=resp.data;
                            setStudlistarr(data.obj);
                            setCount(data.datacount);

                            const total=data.datacount;

                            setPageCount(Math.ceil(total/limit));
                            setLoader(false);
                        }
                    }} placeholder='Search Student Name OR Enroll ID'/>
                    <span className="input-group-btn">
                        <button className="btn btn-info" type="button" disabled={isnewbtn} onClick={getStudlist}>SEARCH</button>
                    </span>
                </div>

            </div>

            
            <div className="card mb-4 mt-3">
                <div className="card-header" style={tableheadline}>
                    <i className="fas fa-table me-1"></i>
                    STUDENT LIST - TOTAL NO OF STUDENTS ( {count} )
                </div>
                <div className="card-body">
                    
                {!loader?
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th style={txtblod}>Name</th>
                                    <th style={txtblod}>Mobile</th>
                                    <th style={txtblod}>Joining Date</th>
                                </tr>
                            </thead>
                            
                            <tbody>

                            {studlistarr.map((e)=>
                                
                                
                                
                                <tr key={e.id}>
                                    <td style={txtblod}>{e.name}</td>
                                    <td>{e.mobile}</td>
                                    <td>{dateFormat(e.created_at, "dd-mm-yyyy")}</td>
                                </tr>
                            )}
                                    
                            </tbody>
                        </table>
                    </div>
                :<TableLoader/>}
                </div>
            </div>

            <div id="react-paginate">
            <ReactPaginate
            breakLabel="..."
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageCount}
            // pageCount={20}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}           

            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            previousClassName={'page-item'}
            previousLinkClassName={"page-link"}
            nextClassName={'page-item'}
            nextLinkClassName={"page-link"}
            // disabledClassName={"pagination__link--disabled"}
            breakClassName={'page-item'}
            breakLinkClassName={"page-link"}
            
            activeClassName={'active'} />            
            </div>

            

        </div>


        </>
    );

}

export default MockStudentContent;